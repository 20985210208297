<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <router-link class="back-link router-link-active" to="/config">
            <svg data-v-231527a2="" fill="none" height="12" viewBox="0 0 8 12" width="8"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M7.41 10.58L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.58Z" data-v-231527a2="" fill="#0033A0"></path>
            </svg>
            назад
          </router-link>

          <h1>Настройка подсистемы «ЖС / БС»
            <v-btn outlined @click="$refs['fileInput'].click()" style="margin-left:150px">загрузить файл конфигурации</v-btn>
            <input ref="fileInput" style="display: none" type="file" @change="loadJson">
          </h1>



          <h2>Жизненные ситуации</h2>

          <table class="config-list-table">
            <tbody>
            <template v-for="situation in situationDicts">
              <tr v-if="situation.situationType==='LIVE_SITUATION'" :key="'zhs'+situation.id">
                <td>{{ situation.situationName }}</td>
                <td>

                  <div class="edit">
                    <router-link :to="'/config/SituationsConfigAdd/'+situation.id">
                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                            fill="#0033A0"/>
                      </svg>
                    </router-link>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>

          <h2>Бизнес-ситуации</h2>

          <table class="config-list-table">
            <tbody>
            <template v-for="situation in situationDicts">
              <tr v-if="situation.situationType==='BUSINESS_SITUATION'" :key="'bs'+situation.id">
                <td>{{ situation.situationName }}</td>
                <td>

                  <div class="edit">
                    <router-link :to="'/config/SituationsConfigAdd/'+situation.id">
                      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                            fill="#0033A0"/>
                      </svg>
                    </router-link>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>

          <div class="save-buttons">
            <v-btn color="primary" @click="$router.push('/config/SituationsConfigAdd/')">добавить ситуацию</v-btn>
          </div>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>

</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: "SituationsConfig",
  props: {},
  components: {},
  data() {
    return {
      situationDicts: [],
      errorText: null,
      apiLoaded: false
    };
  },
  methods: {
    async loadDict() {
      let req = await this.$getApi("/situations/getSituationsDict")
      if (req.ok) {
        this.situationDicts = req.payload
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async initialize() {
      await this.loadDict()
    },


    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this
      reader.onload = async function () {
        let dict

        try {
          dict = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать конфигурацию в формате JSON"
          return
        }

        if (confirm("Заменить справочник ситуаций на загруженный в конфигурационном файле?")) {
          let req = await this.componentThis.$postApi("/situations/replaceSituationsDict", dict)
          if (req.ok) {
            this.componentThis.initialize()
          } else {
            this.componentThis.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          }
        }
      };

      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },
    
  },
  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true
  }
};
</script>
